<template>
    <pc-btn class="btn-sm btn-primary mt-4" @click="addToCart()" :disabled="loading">
        <span v-if="added" class="material-symbols-outlined">done</span>
        <span v-else-if="loading" class="material-symbols-outlined">pending</span>
        <span v-else class="material-symbols-outlined">add</span>
        {{ $t('Add') }}
    </pc-btn>
</template>

<script lang="ts">
import Request from "@/lib/apollo/request";
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';

@Component
export default class PCAddToCartButton extends Vue {
    @Prop()
    readonly product: any;

    @Prop()
    readonly preCallback: () => void;

    added = false;
    loading = false;

    addToCart() {
        this.loading = true;
        Request.make('/api/cart/exists/' + encodeURIComponent(this.product.id), Request.GET).then((res: any) => {
            if (this.product.hasOtherForms) {
                this.$root.$emit('other-forms-dialog', this.product);
            } else if (res.exists === true) {
                this.$root.$emit('enable-modal-already-in-cart', this.product, res.quantity);
            } else if (this.product.previouslyOrdered === true && this.$store.state.user.previouslyOrderedEnabled) {
                this.$root.$emit('enable-modal-previously-ordered', this.product);
            } else {
                this.$addToCart(this.product, 1, false, {}).then(() => {
                    this.$root.$emit('refresh-cart-count');
                    this.$flashMessage('Product added to cart', 'success');
                });
            }

            this.loading = false;
        });
    }

    @Watch('product', { deep: true })
    onProductChange() {
        this.added = false;
        this.loading = false;
    }
}
</script>
