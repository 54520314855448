declare module 'vue/types/vue' {
    interface Vue {
        $errorState(resultObj: any, field: string): any;
        $errorStateCVL(resultObj: any, field: string): any;
    }
}

export default class FormPlugin {
    public static install(Vue: any, options: any) {
        Vue.prototype.$errorState = function (resultObj: any, field: string) {
            const empty = {
                'has-error': false,
                'feedback': [],
            };

            if (typeof resultObj.msg === 'undefined') {
                return empty;
            }

            const fields = field.split('.');

            let output;
            if (fields.length === 1) {
                output = resultObj.msg[fields[0]];
            } else if (fields.length > 1) {
                let msgObj = resultObj.msg[fields[0]];
                fields.reduce((prev, current) => {
                    msgObj = msgObj[current];
                    return current;
                });
                output = msgObj;
            } else {
                return empty;
            }

            if (!output) {
                return empty;
            }

            return {
                'has-error': output.length > 0,
                'feedback': output,
            };
        }
        Vue.prototype.$errorStateCVL = function (resultObj: any, field: string) {
            const output: any = {
                'has-error': false,
                'feedback': [],
            };

            if (resultObj['@type'] === 'ConstraintViolationList') {
                if (typeof resultObj.violations === 'object') {
                    for (const key in resultObj.violations) {
                        if (resultObj.violations[key].propertyPath === field) {
                            output["has-error"] = true;
                            output.feedback.push(resultObj.violations[key].message);
                        }
                    }
                }
            }

            return output;
        }
    }
}
