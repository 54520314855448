//import { Vue } from 'vue/types/vue';
import Apollo, { Request } from "@/lib/apollo";

declare module 'vue/types/vue' {
    interface Vue {
        $addToCart(product: any, quantity: number, urgent: boolean, formData: any): Promise<any>;
        $updateInCart(id: string, product: any, quantity: number, urgent: boolean, formData: any): Promise<any>;
        $updateFormData(id: string, formData: any): Promise<any>;
        $setQuantityInCart(id: string, quantity: string): Promise<any>;
        $removeFromCart(id: string): Promise<any>;
        $clearCart(): Promise<any>;
    }
}

export default class CartPlugin {
    public static install(Vue: any, options: any) {
        Vue.prototype.$addToCart = function (product: any, quantity = '1', urgent = false, formData: any = {}): Promise<any> {
            return Apollo.get('carts').create({
                product: '/api/products/' + encodeURIComponent(product['id']),
                quantity: parseInt(quantity),
                urgent: urgent,
                formData: formData,
            });
        };
        Vue.prototype.$updateInCart = function (id: string, product: any, quantity = '1', urgent = false, formData: any = {}): Promise<any> {
            return Apollo.get('carts').update(id, {
                product: '/api/products/' + encodeURIComponent(product['id']),
                quantity: parseInt(quantity),
                urgent: urgent,
                formData: formData,
                address: {
                    zipCode: (formData.zipCode ? formData.zipCode : null),
                    street: (formData.street ? formData.street : null),
                    number: (formData.number ? formData.number : null),
                    city: (formData.city ? formData.city : null),
                    country: (formData.country ? '/api/countries/' + encodeURIComponent(formData.country) : null),
                },
            });
        };
        Vue.prototype.$updateFormData = function (id: string, formData: any = {}): Promise<any> {
            return Apollo.get('carts').update(id, {
                formData: formData,
            });
        };
        Vue.prototype.$setQuantityInCart = function (id: string, quantity: string): Promise<any> {
            return Request.make('/api/cart/set-item-quantity/' + encodeURIComponent(id) + '/' + encodeURIComponent(quantity));
        };
        Vue.prototype.$removeFromCart = function (id: string): Promise<any> {
            return Apollo.get('carts').delete(id);
        };
        Vue.prototype.$clearCart = function (): Promise<any> {
            return Apollo.get('carts').delete('_all');
        };
    }
}
