<template>
    <div class="login">
        <div>
            <div>
                <div class="logo"><img src="/logo-dark.png" alt="Postma &amp; Cohen" /></div>

                <div class="alert alert-dismissible fade show"
                    role="alert"
                    v-bind="$store.state.flashMessageOptions"
                    v-if="$store.getters.hasFlash"
                >
                    {{ $store.state.flashMessage }}
                    <button type="button" class="btn-close" @click="$store.commit('flashMessage', '')" aria-label="Sluiten"></button>
                </div>

                <slot />
            </div>
        </div>
        <div>
            <div>
                <video width="100%" height="100%" autoplay loop muted playsinline>
                    <source src="/video/PostmaCohen.mp4" type="video/mp4; codecs='hvc1'">
                    <source src="/video/PostmaCohen.webm" type="video/webm">
                </video>

                <p>&nbsp;</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Login extends Vue {}
</script>

<style lang="scss">
@import "bootstrap";

.login {
    display: flex;
    width: 100%;
    height: 100%;

    > div:first-child {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(lg) {
            width: 100% !important;
        }

        > div {
            width: 55%;

            @include media-breakpoint-down(lg) {
                width: 85% !important;
            }
        }

        div.logo {
            width: 100%;
            margin-bottom: 7.8571em;

            @include media-breakpoint-down(lg) {
                margin-bottom: 3.92855em !important;
            }

            > img {
                width: 65%;
            }
        }
    }
    > div:last-child {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(lg) {
            display: none;
        }

        > div {
            width: 720px;
        }
    }

    > div:first-child {
        background-color: #FFF;
    }
}
</style>
